import { BCSDate } from "../../../common/BCSDate";
import { TimeSpan } from "../timespans/TimeSpan";
import { TimeRecord } from "../TimeRecord";
import { AttendanceBookingStructure } from "./AttendanceBookingStructure";
import { DummyBooking } from "../DummyBooking";

/**
 * Datenstruktur die Buchungen zu Tagen zuordnen und diese so leicher abrufbar macht.
 */
export class BookingsPerDay {
    private bookings: TimeRecord[];

    private timespans: TimeSpan[];
    private pauses: TimeSpan[] = [];

    private dayToBookingsMap: { [date: string]: TimeRecord[] } = {};
    private dayNoAttendanceBookingMap: { [date: string]: DayBookingStructure } = {};
    private dayAllBookingMap: { [date: string]: DayBookingStructure } = {};
    private attenanceWithTimeToBookingMap: {
        [date: string]: { [startTime: number]: AttendanceBookingStructure };
    } = {};
    private attenanceWithoutTimeToBookingMap: {
        [date: string]: { [order: number]: AttendanceBookingStructure };
    } = {};
    private composedDummyBookings: DummyBooking[] = [];

    // Falls es gar keine Anwesenheit gibt:
    private bookingsWithTimeAndDateWithoutAttendance: TimeRecord[] = [];
    private bookingsWithoutTimeAndDateWithoutAttendance: TimeRecord[] = [];

    constructor(givenBookings: TimeRecord[], givenTimeSpans: TimeSpan[]) {
        this.bookings = givenBookings;
        this.timespans = givenTimeSpans;
        this.sortPerDay();
    }

    /**
     *
     * @param date
     * @return null falls kein Treffer
     */
    public getBookingsAtDay(date: BCSDate): DayBookingStructure {
        const dayBookings: DayBookingStructure = this.dayAllBookingMap[date.format("DD-MM-YYYY")];
        if (typeof dayBookings !== "undefined") {
            return dayBookings;
        }
        return null;
    }

    /**
     *
     * @param date
     * @return null falls kein Treffer
     */
    public getNoAttendanceBookingsAtDay(date: BCSDate): DayBookingStructure {
        const dayBookings: DayBookingStructure =
            this.dayNoAttendanceBookingMap[date.format("DD-MM-YYYY")];
        if (typeof dayBookings !== "undefined") {
            return dayBookings;
        }
        return null;
    }

    /**
     *
     * @param date
     * @return null falls kein Treffer
     */
    public getAttendancesAtDay(date: BCSDate): TimeSpan[] {
        const attendances: TimeSpan[] = [];

        const fomattedDate = date.format("DD-MM-YYYY");

        this.getAttendancesFromStructureAtDate(
            this.getAttendancesToBooking(),
            attendances,
            fomattedDate,
        );
        this.getAttendancesFromStructureAtDate(
            this.getAttendancesWithoutTimeToBooking(),
            attendances,
            fomattedDate,
        );

        return attendances;
    }

    public getAttendancesAtDayExpense(date: BCSDate): number {
        let sumOfAttendance: number = 0;

        const fomattedDate = date.format("DD-MM-YYYY");

        sumOfAttendance = this.getAttendancesExpenseSumFromStructureAtDate(
            this.getAttendancesToBooking(),
            sumOfAttendance,
            fomattedDate,
        );
        sumOfAttendance = this.getAttendancesExpenseSumFromStructureAtDate(
            this.getAttendancesWithoutTimeToBooking(),
            sumOfAttendance,
            fomattedDate,
        );

        return sumOfAttendance;
    }

    public getAttendancesToBooking() {
        return this.attenanceWithTimeToBookingMap;
    }

    public getAttendancesToBookingAtDay(dateString: string) {
        return this.attenanceWithTimeToBookingMap[dateString];
    }

    public getAttendancesWithoutTimeToBookingAtDay(dateString: string): {
        [order: number]: AttendanceBookingStructure;
    } {
        return this.attenanceWithoutTimeToBookingMap[dateString];
    }

    public getAttendancesWithoutTimeToBooking() {
        return this.attenanceWithoutTimeToBookingMap;
    }

    public getWithoutAttendancesToBooking() {
        return this.bookingsWithTimeAndDateWithoutAttendance;
    }

    public getWithoutAttendancesWithoutTimeToBooking() {
        return this.bookingsWithoutTimeAndDateWithoutAttendance;
    }

    public addAppointmentBooking(booking: TimeRecord, date: BCSDate) {
        let wasInserted: boolean = false;

        const attenanceWithTimeToBookingMapAtDay =
            this.attenanceWithTimeToBookingMap[date.format("DD-MM-YYYY")];
        let startTime: string = booking.getStartTime().getTime().toString();
        for (startTime in attenanceWithTimeToBookingMapAtDay) {
            const attenanceToBookingDatastructure: AttendanceBookingStructure =
                attenanceWithTimeToBookingMapAtDay[startTime];
            if (!attenanceToBookingDatastructure.hasOnlyDuration()) {
                if (attenanceToBookingDatastructure.isInStartEndTime(booking)) {
                    attenanceToBookingDatastructure.addBooking(booking);
                    wasInserted = true;
                    break;
                }
            }
        }
        if (!wasInserted) {
            if (!booking.hasDurationOnly()) {
                this.bookingsWithTimeAndDateWithoutAttendance.push(booking);
            } else {
                this.bookingsWithoutTimeAndDateWithoutAttendance.push(booking);
            }
        }

        for (var i = 0; i < this.bookingsWithTimeAndDateWithoutAttendance.length; i++) {
            var dateString = new BCSDate(
                this.bookingsWithTimeAndDateWithoutAttendance[i].getDate(),
            ).format("DD-MM-YYYY");
            if (typeof this.dayNoAttendanceBookingMap[dateString] === "undefined") {
                this.dayNoAttendanceBookingMap[dateString] = new DayBookingStructure().addBooking(
                    this.bookingsWithTimeAndDateWithoutAttendance[i],
                );
            } else {
                var day = this.dayNoAttendanceBookingMap[dateString];
                day.addBooking(this.bookingsWithTimeAndDateWithoutAttendance[i]);
            }
        }

        for (var i = 0; i < this.bookingsWithoutTimeAndDateWithoutAttendance.length; i++) {
            var dateString = new BCSDate(
                this.bookingsWithoutTimeAndDateWithoutAttendance[i].getDate(),
            ).format("DD-MM-YYYY");
            if (typeof this.dayNoAttendanceBookingMap[dateString] === "undefined") {
                this.dayNoAttendanceBookingMap[dateString] = new DayBookingStructure().addBooking(
                    this.bookingsWithoutTimeAndDateWithoutAttendance[i],
                );
            } else {
                var day = this.dayNoAttendanceBookingMap[dateString];
                day.addBooking(this.bookingsWithoutTimeAndDateWithoutAttendance[i]);
            }
        }
    }

    private sortPerDay() {
        if (typeof this.timespans != "undefined" && this.timespans.length > 0) {
            let attendanceWithoutTimeCounter = 0;
            // Anwesenheitstrukturen aufbauen, diese beinhalten dann später die einzelnen Buchungen.
            // Dabei sortieren wir Anwesenheiten in Anwesenheitsstrukturen und Pausen merken wir uns.
            for (var i = 0; i < this.timespans.length; i++) {
                const currentTimeSpan: TimeSpan = this.timespans[i];
                const timespanDateString = new BCSDate(currentTimeSpan.getDate()).format(
                    "DD-MM-YYYY",
                );
                if (currentTimeSpan.isAttendance()) {
                    if (currentTimeSpan.hasDurationOnly()) {
                        if (
                            typeof this.attenanceWithoutTimeToBookingMap[timespanDateString] ===
                            "undefined"
                        ) {
                            this.attenanceWithoutTimeToBookingMap[timespanDateString] = {};
                            this.attenanceWithoutTimeToBookingMap[timespanDateString][
                                attendanceWithoutTimeCounter
                            ] = new AttendanceBookingStructure(currentTimeSpan);
                        } else {
                            this.attenanceWithoutTimeToBookingMap[timespanDateString][
                                attendanceWithoutTimeCounter
                            ] = new AttendanceBookingStructure(currentTimeSpan);
                        }

                        attendanceWithoutTimeCounter++;
                    } else {
                        const startInMinutes: number = new BCSDate(
                            currentTimeSpan.getStartTime(),
                        ).getMinutesOfDay();

                        if (
                            typeof this.attenanceWithTimeToBookingMap[timespanDateString] ===
                            "undefined"
                        ) {
                            this.attenanceWithTimeToBookingMap[timespanDateString] = {};
                            this.attenanceWithTimeToBookingMap[timespanDateString][startInMinutes] =
                                new AttendanceBookingStructure(currentTimeSpan);
                        } else {
                            this.attenanceWithTimeToBookingMap[timespanDateString][startInMinutes] =
                                new AttendanceBookingStructure(currentTimeSpan);
                        }
                    }
                } else if (currentTimeSpan.isPause()) {
                    if (currentTimeSpan.getEffortExpense() !== null) {
                        this.pauses.push(<TimeSpan>currentTimeSpan);
                        this.bookings.push(currentTimeSpan);
                    }
                }
            }

            for (var i = 0; i < this.bookings.length; i++) {
                var booking: TimeRecord = this.bookings[i];
                if (booking.isMiscellaneousEffort()) {
                    continue;
                }
                var wasInserted = false;
                if (!booking.hasDurationOnly()) {
                    for (var date in this.attenanceWithTimeToBookingMap) {
                        const dateAttenanceWithTimeToBookingMap =
                            this.attenanceWithTimeToBookingMap[date];
                        for (const startTime in dateAttenanceWithTimeToBookingMap) {
                            var attenanceToBookingDatastructure: AttendanceBookingStructure =
                                dateAttenanceWithTimeToBookingMap[startTime];
                            if (!attenanceToBookingDatastructure.hasOnlyDuration()) {
                                if (attenanceToBookingDatastructure.isInStartEndTime(booking)) {
                                    attenanceToBookingDatastructure.addBooking(booking);
                                    wasInserted = true;
                                    break;
                                }
                            }
                        }
                    }
                } else {
                    for (var date in this.attenanceWithoutTimeToBookingMap) {
                        const dateAttenanceToBookingDatastructure =
                            this.attenanceWithoutTimeToBookingMap[date];
                        for (const orderNumber in dateAttenanceToBookingDatastructure) {
                            var attenanceToBookingDatastructure: AttendanceBookingStructure =
                                dateAttenanceToBookingDatastructure[orderNumber];
                            if (attenanceToBookingDatastructure.hasOnlyDuration()) {
                                if (attenanceToBookingDatastructure.isNotAlreadyFull(booking)) {
                                    attenanceToBookingDatastructure.addBooking(booking);
                                    wasInserted = true;
                                    break;
                                }
                            }
                        }
                    }
                }
                /*
                 * Merken uns die Buchungen, bei denen keine Zugehörigen Anweseheiten gefunden wurden,
                 * um die unter dem Block "Anwesenheit nicht zugeördnet" anzuzeigen.
                 */
                if (!wasInserted) {
                    // Ohne Anwesenheit machen Dummies keinen Sinn, da diese Lücken in Anwesenheiten ausfüllen.
                    if (!booking.isDummy()) {
                        if (!booking.hasDurationOnly()) {
                            this.bookingsWithTimeAndDateWithoutAttendance.push(booking);
                        } else {
                            this.bookingsWithoutTimeAndDateWithoutAttendance.push(booking);
                        }
                    }
                }
            }
        } else {
            /*
             * Falls bisher keine Anwesenheiten gepflegt wurden, merken wir uns alle Buchungen als nicht zu einer Anwesenheit gehörend,
             * um die unter dem Block "Anwesenheit nicht zugeordnet" anzuzeigen.
             */
            for (var i = 0; i < this.bookings.length; i++) {
                var booking: TimeRecord = this.bookings[i];
                if (booking.isMiscellaneousEffort()) {
                    continue;
                }
                var wasInserted = false;
                // Ohne Anwesenheit machen Dummies keinen Sinn, da diese Lücken in Anwesenheiten ausfüllen.
                if (!booking.isDummy()) {
                    if (!booking.hasDurationOnly()) {
                        this.bookingsWithTimeAndDateWithoutAttendance.push(booking);
                    } else {
                        this.bookingsWithoutTimeAndDateWithoutAttendance.push(booking);
                    }
                }
            }
        }

        for (var i = 0; i < this.bookingsWithTimeAndDateWithoutAttendance.length; i++) {
            var dateString = new BCSDate(
                this.bookingsWithTimeAndDateWithoutAttendance[i].getDate(),
            ).format("DD-MM-YYYY");
            if (typeof this.dayNoAttendanceBookingMap[dateString] === "undefined") {
                this.dayNoAttendanceBookingMap[dateString] = new DayBookingStructure().addBooking(
                    this.bookingsWithTimeAndDateWithoutAttendance[i],
                );
            } else {
                var day = this.dayNoAttendanceBookingMap[dateString];
                day.addBooking(this.bookingsWithTimeAndDateWithoutAttendance[i]);
            }
        }

        for (var i = 0; i < this.bookingsWithoutTimeAndDateWithoutAttendance.length; i++) {
            var dateString = new BCSDate(
                this.bookingsWithoutTimeAndDateWithoutAttendance[i].getDate(),
            ).format("DD-MM-YYYY");
            if (typeof this.dayNoAttendanceBookingMap[dateString] === "undefined") {
                this.dayNoAttendanceBookingMap[dateString] = new DayBookingStructure().addBooking(
                    this.bookingsWithoutTimeAndDateWithoutAttendance[i],
                );
            } else {
                var day = this.dayNoAttendanceBookingMap[dateString];
                day.addBooking(this.bookingsWithoutTimeAndDateWithoutAttendance[i]);
            }
        }

        for (var i = 0; i < this.bookings.length; i++) {
            var dateString = new BCSDate(this.bookings[i].getDate()).format("DD-MM-YYYY");
            if (typeof this.dayAllBookingMap[dateString] === "undefined") {
                this.dayAllBookingMap[dateString] = new DayBookingStructure().addBooking(
                    this.bookings[i],
                );
            } else {
                var day = this.dayAllBookingMap[dateString];
                day.addBooking(this.bookings[i]);
            }
        }
    }

    private getAttendancesFromStructure(
        attendanceStructure: { [index: number]: AttendanceBookingStructure },
        sumOfAttendance: TimeSpan[],
    ): TimeSpan[] {
        for (const attandanceKey in attendanceStructure) {
            sumOfAttendance.push(attendanceStructure[attandanceKey].getAttendance());
        }
        return sumOfAttendance;
    }

    private getAttendancesExpenseSumFromStructure(
        attendanceStructure: { [index: number]: AttendanceBookingStructure },
        sumOfAttendance: number,
    ): number {
        for (const attandanceKey in attendanceStructure) {
            sumOfAttendance += attendanceStructure[attandanceKey]
                .getAttendance()
                .getEffortExpense();
        }
        return sumOfAttendance;
    }

    private getAttendancesExpenseSumFromStructureAtDate(
        attendanceStructure: {
            [date: string]: { [startTime: number]: AttendanceBookingStructure };
        },
        sumOfAttendance: number,
        dateString: string,
    ): number {
        const attendanceStructureOneDay = attendanceStructure[dateString];
        if (typeof attendanceStructureOneDay !== "undefined") {
            for (const attandanceKey in attendanceStructureOneDay) {
                sumOfAttendance += attendanceStructureOneDay[attandanceKey]
                    .getAttendance()
                    .getEffortExpense();
            }
        }
        return sumOfAttendance;
    }

    private getAttendancesFromStructureAtDate(
        attendanceStructure: {
            [date: string]: { [startTime: number]: AttendanceBookingStructure };
        },
        sumOfAttendance: TimeSpan[],
        dateString: string,
    ): TimeSpan[] {
        const attendanceStructureOneDay = attendanceStructure[dateString];
        if (typeof attendanceStructureOneDay !== "undefined") {
            for (const attandanceKey in attendanceStructureOneDay) {
                sumOfAttendance.push(attendanceStructureOneDay[attandanceKey].getAttendance());
            }
        }
        return sumOfAttendance;
    }
}

export class DayBookingStructure {
    private bookings: TimeRecord[] = [];
    private miscellaneousEfforts: TimeRecord[] = [];

    private sumOfBookings: number = 0;
    private sumOfBookings_synced: number = 0;
    private sumOfBookings_not_synced: number = 0;

    private bookingOnMiscellaneousSum: number = 0;

    public addBooking(booking: TimeRecord): DayBookingStructure {
        if (booking.isMiscellaneousEffort()) {
            this.bookingOnMiscellaneousSum += booking.getEffortExpense();
            this.insertSortedBooking(booking, this.miscellaneousEfforts);
        } else {
            this.sumOfBookings += booking.getEffortExpense();

            if (booking.hasSyncState() && booking.isSyncOnlyChangedInApp()) {
                this.sumOfBookings_not_synced += booking.getEffortExpense();
            } else {
                this.sumOfBookings_synced += booking.getEffortExpense();
            }

            this.insertSortedBooking(booking, this.bookings);
        }
        return this;
    }

    public getChunkedBookings(): [TimeRecord[]] {
        const bookingsLength = this.bookings.length;
        let currentChunk: TimeRecord[] = [];
        const result: [TimeRecord[]] = [currentChunk];
        for (let i = 0; i < bookingsLength; i++) {
            if (i == 0) {
                currentChunk.push(this.bookings[i]);
            } else {
                const first = this.bookings[i - 1];
                const sec = this.bookings[i];
                if (
                    Math.abs(
                        new BCSDate(first.getEndTime()).minuteDiff(new BCSDate(sec.getStartTime())),
                    ) > 0
                ) {
                    const newChunk: TimeRecord[] = [];
                    newChunk.push(sec);
                    result.push(newChunk);
                    currentChunk = newChunk;
                } else {
                    currentChunk.push(this.bookings[i]);
                }
            }
        }

        return result;
    }

    public getBookings(): TimeRecord[] {
        return this.bookings;
    }

    public getAttendances(): TimeRecord[] {
        return this.bookings;
    }

    public getDayBookingSum(): number {
        return this.sumOfBookings;
    }

    public getDayBookingSumSynced(): number {
        return this.sumOfBookings_synced;
    }

    public getDayBookingSumNotSynced(): number {
        return this.sumOfBookings_not_synced;
    }

    public getDayMiscellaneousBookingSum(): number {
        return this.bookingOnMiscellaneousSum;
    }

    private insertSortedBooking(booking: TimeRecord, bookingArray: TimeRecord[]) {
        const bookingsLength = bookingArray.length;
        if (bookingsLength == 0) {
            bookingArray[0] = booking;
        } else {
            const inserted = false;
            for (let i = 0; i < bookingsLength; i++) {
                if (
                    new BCSDate(bookingArray[i].getStartTime()).isAfter(
                        new BCSDate(booking.getStartTime()),
                    )
                ) {
                    bookingArray.splice(i, 0, booking);
                    return;
                }
            }
            // falls wir oben in der Schleife klein größeres Element gefunden haben, dann fügen wir das neue Element hinten ein.
            bookingArray.push(booking);
        }
    }
}
